import { Component, Input } from '@angular/core';
import { getCoverageType, getOperatorData } from '@soracom/shared/data-access-auth';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SpeedClass } from '@ts/speed-class';

@Component({
  selector: 'app-speed-class-legend',
  templateUrl: './speed-class-legend.component.html',
  styles: [
    `
      :host {
        display: inline-block;
      }
    `,
  ],
})
export class SpeedClassLegendComponent {
  // @ts-expect-error (legacy code incremental fix)
  @Input() subscribers: ExtendedSubscriberInterface[];

  get speedClasses() {
    // FIXME: does this really need to be dynamic? It could only change when the component is re-displayed, right?

    const covType = getCoverageType();
    const necContractCode = getOperatorData().getNecContractCode();

    let speedClasses = ['s1.minimum', 's1.slow', 's1.standard', 's1.fast', 's1.4xfast'];

    if (covType === 'jp') {
      speedClasses.push(...['u1.slow', 'u1.standard']);
    }

    const operatorId = getOperatorData().getOperatorId();

    // This component is now in charge of what speed classes it shows, although it still delegates validating them for the current user to SpeedClass:
    speedClasses = SpeedClass.validateSpeedClassListForCurrentUser(operatorId, speedClasses, necContractCode);

    return speedClasses.map((id) => SpeedClass.get(id));
  }
}
