<section dsDialogContent>
  <p [innerHTML]="explanatoryText | translate"></p>
  <p [innerHTML]="explanatoryTextMoreInfo | translate"></p>
  <ds-notices [notices]="notices"></ds-notices>

  <app-subscriber-batch-change [updater]="updater"></app-subscriber-batch-change>

  <ng-container *ngIf="!reasonNewPlanCannotBeSelected">
    @if(select) {
      <ui-select [content]="select"></ui-select>
    }

    <ng-container *ngIf="warningConfirmationMessage">
      <label class="ds-checkbox">
        <input class="x-agree-device-terms" type="checkbox" [(ngModel)]="agreedToWarningConfirmationMessage" />
        <div>
          <ui-text-content [content]="warningConfirmationMessage"></ui-text-content>
        </div>
      </label>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="reasonNewPlanCannotBeSelected">
    <div class="ds-notice">
      <ui-text-content [content]="reasonNewPlanCannotBeSelected"></ui-text-content>
    </div>
  </ng-container>
</section>
<footer dsDialogFooter>
  <button class="ds-button --primary x-confirm" (click)="confirm()" [disabled]="!canConfirm">
    <span [innerHTML]="'generic.button_title.change' | translate"></span>
  </button>
  <button class="ds-button x-cancel" (click)="close()" [disabled]="!canClose()" uiFocusMe>
    <span [innerHTML]="'generic.button_title.cancel' | translate"></span>
  </button>
</footer>
