import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { Logger } from '@soracom/shared-ng/logger-service';
import { SimApiService, SubscriberApiService } from '@soracom/shared-ng/soracom-api-ng-client';
import { LoginUserDataService } from '@soracom/shared/data-access-auth';
import { nonEditableBundleIds, SubscriberBundleId, SubscriptionPlan } from '@soracom/shared/subscription-plan';
import deepEqual from 'fast-deep-equal';
import { SoracomApiService } from '../../../../app/shared/components/soracom_api.service';
import { ExtendedSubscriberInterface } from '@soracom/shared/subscriber';
import { SubscriberBatchBundlesUpdater } from '../../shared/SubscriberBatchBundlesUpdater';
import { DsModalContentBase } from '@soracom/shared-ng/ui-ds-modal';
import { UiSelect, UiSelectDelegate } from '../../soracom-ui/ui-select/UiSelect';
import { LegacyTextContent } from '@soracom/shared-ng/soracom-ui-legacy';
import { AvailableAirSubscriptions, AvailableAirSubscriptionsService } from '@ts/available-air-subscriptions-service';
import { DsNotice, DsNoticeAlert } from '@soracom/shared/sds-utils';
import { parseApiErrorReason } from '@soracom/shared-ng/util-common';

export interface ChangeSubscribersBundlesComponentInput {
  subscribers: ExtendedSubscriberInterface[];
}

@Component({
  selector: 'app-change-subscribers-bundles',
  templateUrl: './change-subscribers-bundles.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeSubscribersBundlesComponent
  extends DsModalContentBase<ChangeSubscribersBundlesModalData, ChangeSubscribersBundlesCompletionData>
  implements OnInit, UiSelectDelegate
{
  cdRef = inject(ChangeDetectorRef);
  notices: DsNotice[] = [];

  constructor(
    private logger: Logger,
    private apiService: SoracomApiService,
    private loginUserDataService: LoginUserDataService,
    private subscriberApiService: SubscriberApiService,
    private simApiService: SimApiService,
  ) {
    super();
  }

  /**
   * Dynamic property because i18n string depends on input.
   */
  get explanatoryText(): string {
    // @ts-expect-error (legacy code incremental fix)
    const bundleTerminology = SubscriptionPlan.bundleTerminologyId(this.subscribers);
    const i18nId = `ChangeSubscribersBundlesComponent.explanatoryText.${bundleTerminology}`;
    return i18nId;
  }

  get explanatoryTextMoreInfo() {
    // @ts-expect-error (legacy code incremental fix)
    const bundleTerminology = SubscriptionPlan.bundleTerminologyId(this.subscribers);
    const i18nId = `ChangeSubscribersBundlesComponent.explanatoryTextMoreInfo.${bundleTerminology}`;
    return i18nId;
  }

  // @ts-expect-error (legacy code incremental fix)
  updater: SubscriberBatchBundlesUpdater;

  // @ts-expect-error (legacy code incremental fix)
  select: UiSelect<SubscriberBundleId>;

  get newBundleName(): SubscriberBundleId | undefined {
    return this.select?.value;
  }

  async ngOnInit() {
    this.logger.debug('ngOnInit()', this);
    let availableAirSubscriptions: AvailableAirSubscriptions;
    this.updater = new SubscriberBatchBundlesUpdater(
      this.logger,
      this.apiService,
      // @ts-expect-error (legacy code incremental fix)
      this.subscribers,
      this.subscriberApiService,
      this.simApiService,
    );

    const operatorId = this.loginUserDataService.getLoginUser()?.operatorId;
    // @ts-expect-error (legacy code incremental fix)
    const planName = this.modalData.subscribers[0].subscription;

    try {
      availableAirSubscriptions = await AvailableAirSubscriptionsService.getAvailableAirSubscriptions();
      const bundleNames = availableAirSubscriptions.find((plan) => plan.name === planName)?.bundles || [];
      // @ts-expect-error (legacy code incremental fix)
      const bundleTerminology = SubscriptionPlan.bundleTerminologyId(this.subscribers);
      this.select = new UiSelect(bundleNames, this);
      this.select.labelId = 'SubscriberBatchBundlesUpdater.chooseBundle.' + bundleTerminology;
      this.select.onValueChange = (select) => {
        const newValue = select.value;
        this.updater.newBundles = newValue ? [newValue] : [];
        this.cdRef.markForCheck();
      };
      const firstBundles = this.updater.subscribers[0].bundles || [];
      if (this.updater.subscribers.every((s) => deepEqual(s.bundles, firstBundles))) {
        this.select.value = firstBundles[0] as SubscriberBundleId;
      }
    } catch (e) {
      this.notices = [DsNoticeAlert(parseApiErrorReason(e))];
    }
  }

  get subscribers() {
    return this.modalData && this.modalData.subscribers;
  }

  get outputType(): ExtendedSubscriberInterface[] {
    return [];
  }

  get output(): ExtendedSubscriberInterface[] {
    return this.updater.subscribers;
  }

  get canConfirm() {
    if (this.warningConfirmationMessage && !this.agreedToWarningConfirmationMessage) {
      return false;
    }

    const newBundles = this.updater.newBundles;

    const changeableSubscribers = this.updater.subscribers.filter((subscriber) => {
      return !deepEqual(subscriber.bundles, newBundles);
    });

    const changesExist = changeableSubscribers.length > 0;

    return this.updater.canUpdate && changesExist;
  }

  override canClose() {
    return this.updater.state !== 'busy';
  }

  /**
   * If the target subscribers contain >0 subscribers whose bundle is not editable, this returns i18n key/params for the message indicating why the bundle cannot be changed. Otherwise, undefined (meaning it can be changed normally),
   */
  get reasonNewPlanCannotBeSelected() {
    const notChangeable = new Set<SubscriberBundleId>();
    // @ts-expect-error (legacy code incremental fix)
    for (const subscriber of this.subscribers) {
      for (const bundle of subscriber.bundles || []) {
        if (nonEditableBundleIds.includes(bundle)) {
          notChangeable.add(bundle);
        }
      }
    }

    if (notChangeable.size === 0) {
      return undefined; // change allowed
    } else {
      return LegacyTextContent.translation(
        'ChangeSubscribersBundlesComponent.helpTextForSimWhoseBundleCannotBeChanged',
        {
          bundleName: [...notChangeable].join('/'),
        },
      );
    }
  }

  uiSelectHelpText(select: UiSelect) {
    if (!this.newBundleName) {
      return undefined;
    }

    // @ts-expect-error (legacy code incremental fix)
    const bundleTerminology = SubscriptionPlan.bundleTerminologyId(this.subscribers);

    // Show the "already using bundle X" help to explain why the confirm button is disabled, if needed:
    // @ts-expect-error (legacy code incremental fix)
    if (this.subscribers.every((s) => s.bundles?.length === 1 && s.bundles?.[0] === this.newBundleName)) {
      // 🌸
      const singleOrMultiple = this.updater.subscribers.length === 1 ? 'single' : 'multiple';

      const helpTextId = `ChangeSubscribersBundlesComponent.helpTextForNoChanges.${bundleTerminology}.${singleOrMultiple}`;

      const count = String(this.updater.subscribers.length);
      const bundleName = this.updater.newBundles[0] || '???';
      const params = { count, bundleName };
      const helpTextContent = LegacyTextContent.translation(helpTextId, params);

      return helpTextContent;
    }

    return undefined;
  }

  get confirmButtonTitle() {
    return 'generic.button_title.change';
  }

  get warningConfirmationMessage(): LegacyTextContent | false {
    if (this.newBundleName === 'D-300MB') {
      return LegacyTextContent.translation('ChangeSubscribersBundlesComponent.confirmation.planD300MB');
    }
    return false;
  }

  agreedToWarningConfirmationMessage = false;

  confirm() {
    this.updater.performUpdate().then((updater) => {
      if (updater.state === 'succeeded') {
        const completionData: ChangeSubscribersBundlesCompletionData = {
          success: true,
          updatedSubscribers: this.output,
        };
        this.close(completionData);
      } else {
        // Do nothing, because the batch updater is now in charge of displaying error messages.
      }
    });
  }
}

export interface ChangeSubscribersBundlesModalData {
  subscribers: ExtendedSubscriberInterface[];
}

export interface ChangeSubscribersBundlesCompletionData {
  updatedSubscribers?: ExtendedSubscriberInterface[];
  success?: boolean;
}
